var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('main-title', [_vm._v(" Подбор поисковых запросов ")]), _c('main-description', [_vm._v(" Найдите самые эффективные поисковые запросы для своих товарных карточек ")]), _c('app-tabs', {
    staticClass: "tabs",
    attrs: {
      "tabs": _vm.tabs
    },
    model: {
      value: _vm.tabs_model,
      callback: function ($$v) {
        _vm.tabs_model = $$v;
      },
      expression: "tabs_model"
    }
  }), _c('div', {
    staticClass: "tab__content"
  }, [_c('app-select', {
    staticClass: "marketplace-select",
    attrs: {
      "items": _vm.select_marketplace_items,
      "fullwidth": ""
    },
    model: {
      value: _vm.selectedMarketplace,
      callback: function ($$v) {
        _vm.selectedMarketplace = $$v;
      },
      expression: "selectedMarketplace"
    }
  }), _vm.tabs_model.id === 1 ? _c('div', [_c('div', {
    staticClass: "search-wrap"
  }, [_c('entity-search', {
    attrs: {
      "placeholder": "Название категории или ссылка (URL) категории",
      "no-data-text": "Категории не найдены",
      "text-prop": "c_name_path",
      "loader": _vm.searchLoader,
      "value": _vm.searchCategoryValue,
      "selectedMp": _vm.selectedMarketplace.id
    },
    on: {
      "select": _vm.onSelectSearchCategoryValue,
      "change-value": _vm.searchCategoryValueChange
    }
  }), _c('p', {
    staticClass: "search-text"
  }, [_vm._v(" Например "), _c('span', {
    staticClass: "search-text__link",
    on: {
      "click": function ($event) {
        return _vm.searchCategoryValueChange(_vm.searchExamples[0]);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.searchExamples[0]) + " ")])])], 1)]) : _vm._e(), _vm.tabs_model.id === 2 ? _c('div', [_c('div', {
    staticClass: "search-wrap"
  }, [_c('entity-search', {
    attrs: {
      "placeholder": "Список SKU или ссылок на товары (по одному в строке)",
      "text-prop": "c_name_path",
      "value": _vm.searchSkuValue,
      "selectedMp": _vm.selectedMarketplace.id,
      "packet-input": true
    },
    on: {
      "change-value": _vm.searchSkuValueChange
    }
  }), _c('p', {
    staticClass: "search-text"
  }, [_vm._v(" Например "), _c('span', {
    staticClass: "search-text__link",
    on: {
      "click": function ($event) {
        return _vm.searchSkuValueChange(_vm.searchSkuExamples[0]);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.searchSkuExamples[0]) + " ")]), _c('br'), _vm._v(" или "), _c('span', {
    staticClass: "search-text__link",
    on: {
      "click": function ($event) {
        return _vm.searchSkuValueChange(_vm.searchSkuExamples[1]);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.searchSkuExamples[1]) + " ")])])], 1)]) : _vm._e(), _vm.tabs_model.id === 3 ? _c('div', [_c('div', {
    staticClass: "search-wrap"
  }, [_c('entity-search', {
    attrs: {
      "placeholder": "Список запросов (по одному в строке)",
      "text-prop": "c_name_path",
      "value": _vm.searchQueriesValue,
      "selectedMp": _vm.selectedMarketplace.id,
      "packet-input": true
    },
    on: {
      "change-value": _vm.searchQueriesValueChange
    }
  }), _c('p', {
    staticClass: "search-text"
  }, [_vm._v(" Например "), _c('span', {
    staticClass: "search-text__link",
    on: {
      "click": function ($event) {
        return _vm.searchQueriesValueChange(_vm.searchQueriesExamples[0]);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.searchQueriesExamples[0]) + " ")])])], 1)]) : _vm._e(), _c('button', {
    staticClass: "action-button btn-blue btn-search",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.search
    }
  }, [false ? _c('span', {
    staticClass: "loading-overlay"
  }, [_c('loading-spinner', {
    staticClass: "spinner"
  }), _c('span', {
    staticClass: "loading-overlay__text"
  }, [_vm._v(" Идет поиск ")])], 1) : _vm._e(), _c('span', [_vm._v(" Найти запросы ")])])], 1), _c('div', {
    ref: "table",
    attrs: {
      "id": "table"
    }
  }, [(_vm.tabs_model.id === 1 && _vm.categorySearchId !== 0 || _vm.tabs_model.id === 2 && _vm.skuList.length > 0 || _vm.tabs_model.id === 3 && _vm.queriesList.length > 0) && _vm.reportSettings ? _c('div', [_c('div', {
    staticClass: "table-filters"
  }, [_c('div', {
    staticClass: "minus-words"
  }, [_c('button', {
    staticClass: "btn-outline btn-width-auto",
    on: {
      "click": _vm.openMinusWordsModal
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/plus.svg`)
    }
  }), _vm._v(" Указать минус-слова ")]), _c('Tooltip', {
    attrs: {
      "position": "top right"
    }
  }, [_vm._v(" Укажите минус-слова для исключения нерелевантных запросов из подобранного списка. "), _c('br'), _c('br'), _vm._v(" Например, если ввести “красные” - из списка будут удалены все запросы, содержащие “красные”: платья вечерние красные и тд. ")])], 1), _c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId()
    }
  })], 1), _c('div', {
    staticClass: "mt32"
  }, [_c('Queries-selection-table', {
    attrs: {
      "type": _vm.tabs_model.id,
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings
    }
  })], 1)]) : _vm._e()])], 1), _c('modal-minus-words', {
    ref: "ModalMinusWords",
    on: {
      "complete": _vm.onMinusWordsAdded
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };