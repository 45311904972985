import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import LinedTextarea from "@/components/LinedTextarea.vue";
export default {
  mixins: [ModalMixin],
  emits: ["complete"],
  data() {
    return {
      minusWordsValue: "",
      loading: false,
      height: 300,
      resize: "none",
      width: 450
    };
  },
  methods: {
    async submit({
      close
    }) {
      this.loading = true;
      try {
        this.loading = false;
        close();
        this.$emit("complete", this.minusWords);
      } catch (e) {
        this.loading = false;
      }
    }
  },
  computed: {
    minusWords() {
      return this.minusWordsValue.split("\n").filter(item => item !== "");
    },
    actions() {
      return ["cancel", {
        name: "submit",
        class: "btn-blue",
        text: "Сохранить",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        loading: this.loading
      }];
    },
    textareaStyles() {
      return {
        height: this.height + "px",
        width: "100%",
        resize: this.resize
      };
    }
  },
  components: {
    Modal,
    LinedTextarea
  }
};