import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import DataTable from "@/components/DataTable/DataTable";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    type: {
      type: Number
    }
  },
  methods: {
    async tableLoader({
      query
    }) {
      if (query.action === "export") {
        query.page_size = 100000;
      }
      let result = {};
      if (this.type === 1) {
        result = await this.$store.dispatch("seo/getSearchQueriesByCategory", {
          ...this.reportSettings,
          ...query
        });
      }
      if (this.type === 2) {
        result = await this.$store.dispatch("seo/getSearchQueriesBySku", {
          ...this.reportSettings,
          ...query
        });
      }
      if (this.type === 3) {
        result = await this.$store.dispatch("seo/getSearchQueriesByQueries", {
          ...this.reportSettings,
          ...query
        });
      }
      if (query.action === "export") {
        //console.log(query);
        let data = result.data;
        const titleNames = this.columns.map(columnRaw => {
          const {
            name,
            title
          } = columnRaw;
          return {
            name,
            title
          };
        });
        let option = {};
        const dataset = data.map(el => {
          let object = {};
          titleNames.forEach(item => {
            object[item.name] = el[item.name];
          });
          return object;
        });
        option.type = "excel";
        option.datas = [{
          sheetData: dataset,
          sheetName: "Page 1",
          sheetFilter: Object.keys(dataset[0]),
          sheetHeader: titleNames.map(item => item.title)
        }];
        return option;
      } else {
        return {
          items: result.data,
          total: result.total
        };
      }
    }
  },
  computed: {
    columns() {
      //console.log('117: this.reportSettings=', this.reportSettings)

      // mm-dd-yyyy -> yyyy-mm-dd
      let date = this.reportSettings.date_from;
      let a = date.split("-");
      date = a[2] + "-" + a[0] + "-" + a[1];
      let date2 = this.reportSettings.date_to;
      a = date2.split("-");
      date2 = a[2] + "-" + a[0] + "-" + a[1];
      const reportSettings = {
        mp: this.reportSettings.datasource,
        date: date,
        date2: date2
        //fbs: 0,
      };
      return [
      /*
      {
          title: "Запросы",
          show: true,
          name: "keyword",
          type: "text",
          width: 200,
          filter: "text",
      },
      */
      {
        title: "Запрос",
        name: "keyword",
        show: true,
        width: 200,
        type: "link",
        noOverflow: true,
        getLink: item => {
          return {
            route: {
              name: "KeywordInfo",
              params: {
                mp: reportSettings.mp,
                k_id: item.k_id
              },
              query: {
                date: reportSettings.date,
                date2: reportSettings.date2
                //fbs: reportSettings.fbs,
              }
            },
            text: item.keyword,
            target: "_blank"
          };
        }
      }, {
        title: `Частотность ${this.reportSettings.datasource.toUpperCase()}`,
        show: true,
        name: "shows",
        type: "number",
        width: 200,
        filter: "numberRange"
      }, this.type === 3 && {
        title: "Тип",
        show: true,
        name: "type",
        type: "slot",
        width: 200
      }, {
        title: "Результатов по запросу",
        show: true,
        name: "tot_keyword_products",
        type: "number",
        width: 200,
        filter: "numberRange"
      }];
    }
  }
};