var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.columns ? _c('div', [_c('data-table', _vm._b({
    attrs: {
      "table-id": "queriesSelectionSkuTable",
      "id": _vm.id,
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "loaderConfig": _vm.reportSettings,
      "selectable": false,
      "paginatable": true,
      "item-id": "id"
    },
    scopedSlots: _vm._u([{
      key: "type",
      fn: function ({
        item
      }) {
        return [item.type === 0 ? _c('span', [_vm._v("Фразовое соответствие")]) : _vm._e(), item.type === 1 ? _c('span', [_vm._v("Связанные запросы")]) : _vm._e()];
      }
    }], null, false, 3976603880)
  }, 'data-table', _vm.$attrs, false))], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };