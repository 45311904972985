var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Указать минус-слова",
      "actions": _vm.actions
    }
  }, [_c('lined-textarea', {
    attrs: {
      "styles": _vm.textareaStyles,
      "has-lines": false
    },
    model: {
      value: _vm.minusWordsValue,
      callback: function ($$v) {
        _vm.minusWordsValue = $$v;
      },
      expression: "minusWordsValue"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };